import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { SERVER_API_KEY_HEADER, SERVER_API_TIMEOUT } from '../constants'

export const authorizationHeaderConfig: (baseUrl: string, apiKey: string | undefined) => AxiosRequestConfig = (
  baseUrl: string,
  apiKey: string | undefined,
) => {
  return {
    baseURL: baseUrl,
    headers: { [SERVER_API_KEY_HEADER]: apiKey ? apiKey : '' },
  }
}

export const isSuccessWithNotModified: (status: number) => boolean = (status) => {
  return status === 200 || status === 304
}

const setUpAxios = () => {
  axios.defaults.timeout = SERVER_API_TIMEOUT

  const onRequestSuccess = (config: any) => {
    console.debug('OnRequestSuccess:', config)
    return config
  }
  const onResponseSuccess = (response: any) => {
    console.debug('onResponseSuccess:', response)
    return response
  }
  const onResponseError = (err: AxiosError) => {
    console.error('onResponseError:', err)
    const status = err.response ? err.response.status : 0
    if (status === 403 || status === 401) {
      // TODO: clear authenticaiton here
      console.error('onResponseError is 403 or 401.  Logoff required', err)
    }
    return Promise.reject(err)
  }
  axios.interceptors.request.use(onRequestSuccess)
  axios.interceptors.response.use(onResponseSuccess, onResponseError)
}

export const axiosConfig = (url: string | null | undefined) =>
  axios.create({
    baseURL: url ? url : '',
  })

export default setUpAxios
